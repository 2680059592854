import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Modal } from 'components'
import './WorkerInfo.scss'
import { Statistics, ActivityLogs, Console, Settings, Checklist } from '../WorkerInfo'
import { useTranslation } from 'react-i18next'
import * as actions from '../../../../RigsData/actions'
import SweetAlert from 'react-bootstrap-sweetalert'
import { CardTurboModal } from '../index'

const RigFullInfo = props => {
  const [modal, setModal] = useState(false)
  const [showAlertIgnore, setShowAlertIgnore] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const { t } = useTranslation('common')
  const titles = [
    t('pages.workers.workerInformation') + ' - ',
    t('pages.workers.workerActivityLogs') + ' - ',
    t('pages.workers.workerChecklist') + ' - ',
    t('pages.workers.workerScreenOutput') + ' - ',
    t('pages.workers.workerSettings') + ' - ',
  ]
  const initTitle = titles.map(item => `${item}${props.newWorker.name}`)
  const [modalTitle, setModalTitle] = useState(0)
  const submitButton = useRef()

  useEffect(() => {
    setTimeout(() => setModal(true), 10)
  }, [])

  useEffect(() => {
    if (!isInitialized && props.newWorker.id) {
      setIsInitialized(true)
    }
  }, [isInitialized, props.newWorker])

  const onCloseModal = async () => {
    setTimeout(() => {
      props.clearRigFullInfo()
      props.onCancel()
    }, 500)
    setModal(false)
  }

  useEffect(() => {
    const rigTime = setInterval(() => {
      props.getRigTime({ rigId: props.newWorker.id })
    }, 10000)
    return () => {
      clearInterval(rigTime)
    }
  }, [])

  const uptime = () => {
    if (props.rig.uptime) {
      const uptimeStr = props.rig.uptime
        .split(',')
        .map((el, key) => {
          if (key === 0) {
            return `${el}${t('pages.workers.d')}`
          }
          if (key === 1) {
            return `${el}${t('pages.workers.h')}`
          }
          if (key === 2) {
            return `${el}${t('pages.workers.m')}`
          }
          if (key === 3) {
            return
          }
          return
        })
        .toString()
        .slice(0, -1)
        .replace(/,/g, '')
      return uptimeStr
    }
  }

  const now = new Date().getTime()
  const lastStatusTime = now - +props.rig.downTime
  const downTime = props.rig.downTime && props.rig.downTime > 0 ? now - +props.rig.downTime : 0
  const getStatusTimeInt = timeData => {
    const time = Math.abs(timeData)
    let result, units
    if (time > 1000 * 60 * 60 * 24) {
      result = parseInt(time / (1000 * 60 * 60 * 24), 10)
      units = t('pages.workers.days')
    } else if (time > 1000 * 60 * 60) {
      result = parseInt(time / (1000 * 60 * 60), 10)
      units = t('pages.workers.hours')
    } else if (time > 1000 * 60) {
      result = parseInt(time / (1000 * 60), 10)
      units = t('pages.workers.minutes')
    } else {
      result = parseInt(time / 1000, 10)
      units = t('pages.workers.seconds')
    }
    return result + ' ' + units
  }

  const submitFunc = async () => {
    modalTitle === 1
      ? await props.deleteRigLogs({ workerId: props.rig.id })
      : modalTitle === 2
      ? setShowAlertIgnore(true)
      : submitButton.current.click()
  }

  const onHandleIgnore = async () => {
    const errorsToIgnore = []
    let fallenOffTheBusErrorId = null
    props.rig.rigErrors.map(item => {
      errorsToIgnore.push(item.id)
      if (item.error.includes('fallen off the bus')) {
        fallenOffTheBusErrorId = item.id
      }
      return item
    })

    const ignoreData = {
      errorsIds: errorsToIgnore.toString(),
      action: 'erase',
      fallenOffTheBusErrorId,
      rigId: props.rig.id,
    }

    const isIgnored = await props.updateRigError(ignoreData)
    isIgnored && setShowAlertIgnore(false)
  }
  const loadAverage = props.rig.average ? +props.rig.average.split(' ')[0] : ''

  const tryRequire = icon => {
    try {
      return require('../../../../../assets/images/coinIcons/' + icon.toLowerCase() + '.png').default
    } catch (err) {
      return require('../../../../../assets/images/coinIcons/none.png').default
    }
  }
  const [isCardTurboModal, setIsCardTurboModal] = useState(false)
  const [showAlertTurnOff, setShowAlertTurnOff] = useState(false)
  const [showAlertStart, setShowAlertStart] = useState(false)
  const [showAlertReboot, setShowAlertReboot] = useState(false)
  const [showAlertHardReboot, setShowAlertHardReboot] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [showAlertPause, setShowAlertPause] = useState(false)
  const [showAlertRedetect, setShowAlertRedetect] = useState(false)
  const [showAlertDeleteCard, setShowAlertDeleteCard] = useState(false)
  const [showAlertoffMiningCard, setShowAlertoffMiningCard] = useState(false)
  const [deleteCard, setDeleteCard] = useState()
  const [offMiningCard, setoffMiningCard] = useState({ card: {}, offMining: false })
  const onHandleDeleteCard = async cardId => {
    const isDeleted = await props.deleteCard({ rigId: props.worker.id, cardId })
    isDeleted && setShowAlertDeleteCard(false)
    isDeleted && props.delayRigUpdate(true)
  }

  const rebootWorker = async workerID => {
    const reboot = {
      rigId: +workerID,
      powerOf: false,
      reboot: true,
      hardReboot: false,
    }
    await props.resetOrPowerOfWorker(reboot).then()
  }

  const hardRebootWorker = async workerID => {
    const reboot = {
      rigId: +workerID,
      powerOf: false,
      reboot: false,
      hardReboot: true,
    }
    await props.resetOrPowerOfWorker(reboot).then()
  }

  const turnOFFWorker = async workerID => {
    const reboot = {
      rigId: +workerID,
      powerOf: true,
      reboot: false,
      hardReboot: false,
    }
    await props.resetOrPowerOfWorker(reboot).then()
  }
  const onHandleoffMiningCard = async ({ obj }) => {
    console.log('🚀 ~ onHandleoffMiningCard ~ obj:', obj)
    await props.offMiningCard({ rigId: props.worker.id, cardId: +obj.card, offMining: obj.offMining })
    setShowAlertoffMiningCard(false)
  }

  const turnOff = () => setShowAlertTurnOff(true)
  const start = () => setShowAlertStart(true)
  const pause = () => setShowAlertPause(true)
  const reboot = () => setShowAlertReboot(true)
  const hardReboot = () => setShowAlertHardReboot(true)
  const redetect = () => setShowAlertRedetect(true)
  const deleteWorker = () => setShowAlertDelete(true)
  return (
    <>
      <div className={'alerts'}>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertDelete}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.deleteRig({ rigId: props.newWorker.id })
            props.onCancel()
            props.clearRigFullInfo()
            setShowAlertDelete(false)
          }}
          onCancel={() => {
            props.onCancel()
            props.clearRigFullInfo()
            setShowAlertDelete(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerRemoved')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertDeleteCard}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            onHandleDeleteCard(deleteCard)
          }}
          onCancel={() => {
            setShowAlertDeleteCard(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.cardRemoved')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertTurnOff}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            turnOFFWorker(props.newWorker.id)
            setShowAlertTurnOff(false)
          }}
          onCancel={() => {
            setShowAlertTurnOff(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerOff')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertStart}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigMinerAction({
              rigId: props.newWorker.id,
              action: 'run',
            })
            setShowAlertStart(false)
          }}
          onCancel={() => {
            setShowAlertStart(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.miningOn')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertPause}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigMinerAction({
              rigId: props.newWorker.id,
              action: 'stop',
            })
            setShowAlertPause(false)
          }}
          onCancel={() => {
            setShowAlertPause(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.miningOff')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertReboot}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            rebootWorker(props.newWorker.id)
            setShowAlertReboot(false)
          }}
          onCancel={() => {
            setShowAlertReboot(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerReboot')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertHardReboot}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            hardRebootWorker(props.newWorker.id)
            setShowAlertHardReboot(false)
          }}
          onCancel={() => {
            setShowAlertHardReboot(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerRebootHard')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertRedetect}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigMinerAction({
              rigId: props.newWorker.id,
              redetect: true,
            })
            setShowAlertRedetect(false)
          }}
          onCancel={() => {
            setShowAlertRedetect(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.redetect')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertoffMiningCard}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            onHandleoffMiningCard({ obj: offMiningCard })
          }}
          onCancel={() => {
            setShowAlertoffMiningCard(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {offMiningCard.offMining ? t('sweetAlerts.offMiningCard') : t('sweetAlerts.onMiningCard')}
        </SweetAlert>
      </div>
      <Modal
        title={t('pages.workers.confirm')}
        onFinish={() => onHandleIgnore()}
        onFinish2={() => setShowAlertIgnore(false)}
        finishTitle={t('pages.workers.clear')}
        finishTitle2={t('pages.workers.close')}
        isOpen={showAlertIgnore}
        onCancel={() => setShowAlertIgnore(false)}
        hideCanselBtn
        className='checklist__ignore-modal'
      >
        {t('pages.workers.allErrorsWillCleared')}
      </Modal>

      <Modal
        title={initTitle[modalTitle]}
        onFinish={modalTitle === 1 || modalTitle === 4 || modalTitle === 2 ? submitFunc : undefined}
        cancelTitle={modalTitle === initTitle[4] ? t('pages.workers.cancel') : t('pages.workers.close')}
        finishTitle={
          modalTitle === 1
            ? t('pages.workers.clearLogs')
            : modalTitle === 2
            ? t('pages.workers.clearErrors')
            : t('pages.workers.save')
        }
        finishTitle2={modalTitle === 2 ? t('pages.workers.submit') : ''}
        isOpen={modal}
        onCancel={onCloseModal}
        className={'worker-info-modal'}
      >
        {props.rig.active ? (
          <p className='rig-status'>
            ({t('pages.workers.uptime')}: <span className='rig-status__date'>{uptime()}</span>)
          </p>
        ) : (
          <p className='rig-status'>
            ({t('pages.workers.downtime')}: <span className='rig-status__date'>{getStatusTimeInt(downTime)}</span>)
          </p>
        )}

        {props.rig.statusDate > 0 && (
          <p className='rig-check'>
            ({t('pages.workers.lastSeen')} <span className='rig-check__date'>{getStatusTimeInt(lastStatusTime)}</span>{' '}
            {t('pages.workers.ago')})
          </p>
        )}

        <div className={'worker-info-main-information d-md-none'}>
          <div className={'wimi_shortInfo'}>
            <div className={'wimi_power'}>
              {props.rig.powerInfo}W LA:{' '}
              {props.rig.average && props.rig.active ? (
                <span
                  className={
                    loadAverage >= 3
                      ? 'red'
                      : loadAverage >= 2 && loadAverage < 3
                      ? 'yellow'
                      : loadAverage < 2
                      ? 'green'
                      : ''
                  }
                >
                  {props.rig.average}
                </span>
              ) : (
                '0 '
              )}
              &nbsp;
              <span
                style={{
                  color: props.rig.eff > 85 ? 'inherit' : props.rig.eff < 61 ? 'red' : 'yellow',
                }}
              >
                EFF: {props.rig.eff}%
              </span>
            </div>
            <div className={'wimi_coin'}>
              <img className={'worker_coin_icon'} alt={props.rig.coin} src={tryRequire(props.rig.coin)} />
              {props.rig.coin}: A:{props.rig.active ? props.rig.gpuTotalShares : 0}&nbsp;
              <span className={'orange'}>R</span>:{props.rig.active ? props.rig.gpuRej : 0}
              &nbsp;<span className={'red'}>I</span>:{props.rig.active ? props.rig.gpuInv : 0}&nbsp;
              <span className={'card__vendor'}>H</span>:{props.rig.active ? props.rig.gpuTotalSpeed : 0}
            </div>
            {props.rig.coinDual && (
              <div className={'wimi_coin wimi_coinDaal'}>
                <img className={'worker_coin_icon'} alt={props.rig.coinDual} src={tryRequire(props.rig.coinDual)} />
                {props.rig.coinDual}: A:{props.rig.gpuTotalSharesDual}&nbsp;<span className={'orange'}>R</span>:
                {props.rig.gpuRejDual} &nbsp;<span className={'red'}>I</span>:{props.rig.gpuInvDual}
              </div>
            )}

            <div className={'wimi_pool'}>
              P: {props.rig.miningPreset?.flightSheets?.[0]?.pool} M: {props.rig.miner}
            </div>
          </div>
          <div className={'mobile-worker-actions'}>
            {props.rig.action !== 'run' &&
              (props.rig.miningPresetId ? (
                <div
                  className='actions__item actions__item_rig'
                  tooltip={t('pages.workers.startMining')}
                  flow={'left'}
                  onClick={() => start()}
                >
                  <i className='fas fa-play'></i>
                </div>
              ) : (
                <div className='actions__item actions__item_rig' tooltip={t('pages.workers.assignPresetStartMining')}>
                  <i className='fas fa-play'></i>
                </div>
              ))}
            {props.rig.action === 'run' && (
              <div
                className='actions__item actions__item_rig'
                tooltip={t('pages.workers.stopMining')}
                flow={'left'}
                onClick={() => pause()}
              >
                <i className='fas fa-stop'></i>
              </div>
            )}
            <div
              className='actions__item actions__item_rig'
              tooltip={t('pages.workers.reboot')}
              flow='left'
              onClick={() => reboot()}
            >
              <i className='fas fa-sync'></i>
            </div>
            {props.rig.videoCards && props.rig.videoCards.length > 0 && (
              <div
                className={'actions__item actions__item_rig'}
                tooltip={t('pages.workers.overclocking')}
                flow='left'
                onClick={() => setIsCardTurboModal(true)}
              >
                <i className='fas fa-sliders-h'></i>
              </div>
            )}
            {isCardTurboModal && (
              <CardTurboModal
                rigId={props.rig.id}
                rigName={props.rig.name}
                onCancel={() => setIsCardTurboModal(false)}
              />
            )}
            <div
              className='actions__item actions__item_rig'
              tooltip={t('pages.workers.turnOff')}
              flow={'left'}
              onClick={() => turnOff()}
            >
              <i className='fas fa-power-off'></i>
            </div>
            <div
              className='actions__item actions__item_rig'
              tooltip={t('pages.workers.hardReboot')}
              flow='left'
              onClick={() => hardReboot()}
            >
              <i className='fas fa-exclamation'></i>
            </div>
            <div
              className='actions__item actions__item_rig'
              tooltip={t('pages.workers.redetect')}
              flow='left'
              onClick={() => redetect()}
            >
              <i className='fa fa-undo'></i>
            </div>
            <div
              className='actions__item actions__item_rig actions__item_delete-rig'
              tooltip={t('pages.workers.deleteWorker')}
              flow={'left'}
              onClick={() => deleteWorker()}
            >
              <i className='fas fa-trash'></i>
            </div>
          </div>
        </div>
        <Tabs selectedIndex={modalTitle} onSelect={index => setModalTitle(index)}>
          <TabList className={'card_tabs'}>
            <Tab>{t('pages.workers.statistics')}</Tab>
            <Tab>{t('pages.workers.activityLogs')}</Tab>
            <Tab>
              {t('pages.workers.checklist')}
              {props.rig.rigErrorsTotal > 0 && <i className='far fa-exclamation-triangle attention-status'></i>}
            </Tab>
            <Tab>{t('pages.workers.console')}</Tab>
            <Tab>
              <span className={'d-none d-sm-block'}>{t('pages.workers.settings')}</span>
              <span className={'d-sm-none'}>
                <i className='fas fa-cogs'></i>
              </span>
            </Tab>
          </TabList>

          <TabPanel>
            <Statistics worker={props.rig} newWorker={props.newWorker} onCancel={props.onCancel} />
          </TabPanel>

          <TabPanel>
            <ActivityLogs worker={props.rig} />
          </TabPanel>

          <TabPanel>
            <Checklist worker={props.rig} rigErrors={props.rig.rigErrors} goToActivityLogs={() => setModalTitle(1)} />
          </TabPanel>

          <TabPanel>
            <Console worker={props.rig} />
          </TabPanel>

          <TabPanel>
            <Settings worker={props.rig} submitRef={submitButton} newWorker={props.newWorker} onCancel={onCloseModal} />
          </TabPanel>
        </Tabs>
      </Modal>
    </>
  )
}

const mapStateToProps = store => ({
  rig: store.rigs.rig,
})

const mapDispatchToProps = {
  deleteRig: actions.deleteRig,
  deleteCard: actions.deleteCard,
  getRigTime: actions.getRigTime,
  deleteRigLogs: actions.deleteRigLogs,
  offMiningCard: actions.offMiningCard,
  updateRigError: actions.updateRigError,
  setRigMinerAction: actions.setRigMinerAction,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
  clearRigFullInfo: actions.clearRigFullInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(RigFullInfo)
