import WorkerActionsMobile from '../components/WorkerActionsMobile'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import uuid from 'node-uuid'
import Cookies from 'js-cookie'
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row } from 'reactstrap'
import Select from 'react-select'
import * as mainActions from '../actions'
import { WorkerActions, WorkerCards } from '../components'
import * as actions from '../../RigsData/actions'
import * as presetActions from '../../MiningPresets/actions'
import * as dcManagementActions from '../../DCManagement/actions'
import SelectWithImages from '../../../components/UI/SelectWithImages'
import { useTranslation } from 'react-i18next'
import MobileFilters from '../components/modals/MobileFilters'
import { TableWorkers } from '../../../components/Table'
import * as actionsProfiles from '../../OverclockingProfiles/actions'
import Loader from '../../../components/Loader'

const WorkersPage = ({
  setRigsList,
  setDopFilterRigsList,
  total,
  rigsList,
  rigsStat,
  visibleWorkerActions,
  setRoomsList,
  dcManagement,
  setRigMinerAction,
  setMiningPresetList,
  coinsList,
  poolsList,
  setWalletsList,
  setAlgoCoinsList,
  walletsList,
  presetsList,
  setPoolsList,
  minersList,
  setMinersList,
  setSlotsList,
  setRigsData,
  isRigsListDownload,
  setFilterRigsList,
}) => {
  const { t } = useTranslation('common')
  const [allRigs, setAllRigs] = useState([])
  const [uncheckAll, setUncheckAll] = useState(false)
  const [openSortList, setOpenSortList] = useState(false)

  const [visibleRigs, setVisibleRigs] = useState([])
  const [workersList, setWorkersList] = useState([])
  const [dcOptions, setDcOptions] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [showFiltersTimer, setShowFiltersTimer] = useState(false)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'currentPage')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'currentPage'))
      : 1,
  )
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )
  const [orderBy, setOrderBy] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'orderBy')
      ? Cookies.get(window.location.pathname + 'dataTable' + 'orderBy')
      : 'id',
  )

  const [orderDirection, setOrderDirection] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'orderDirection')
      ? Cookies.get(window.location.pathname + 'dataTable' + 'orderDirection')
      : 'DESC',
  )

  const setSort = (sortBy, direction) => {
    setOrderBy(sortBy)
    setOrderDirection(direction ? 'ASC' : 'DESC')
  }

  const mobileSort = field => {
    setOrderBy(field)
    setOrderDirection(field === orderBy ? (orderDirection === 'DESC' ? 'ASC' : 'DESC') : 'ASC')
    Cookies.set(window.location.pathname + 'dataTable' + 'orderBy', field, { expires: 30 })
    if (orderBy === field) {
      Cookies.set(
        window.location.pathname + 'dataTable' + 'orderDirection',
        orderDirection === 'ASC' ? 'DESC' : 'ASC',
        { expires: 30 },
      )
    } else {
      Cookies.set(window.location.pathname + 'dataTable' + 'orderDirection', 'ASC', { expires: 30 })
    }
  }

  const parseJson = json => {
    try {
      return JSON.parse(json)
    } catch (e) {
      return ''
    }
  }

  const [status, setStatus] = useState(
    Cookies.get(window.location.pathname + 'rigStatus')
      ? parseJson(Cookies.get(window.location.pathname + 'rigStatus'))
      : { value: 'all', label: t('pages.workers.all') },
  )
  const [dc, setDc] = useState(
    Cookies.get(window.location.pathname + 'rigDC')
      ? parseJson(Cookies.get(window.location.pathname + 'rigDC'))
      : { value: 'all', label: t('pages.workers.all') },
  )
  const [favoriteFirst, setFavoriteFirst] = useState(
    Cookies.get(window.location.pathname + 'favoriteFirst')
      ? Cookies.get(window.location.pathname + 'favoriteFirst') === 'true'
      : false,
  )

  const [coin, setCoin] = useState(
    Cookies.get(window.location.pathname + 'rigCoin')
      ? parseJson(Cookies.get(window.location.pathname + 'rigCoin'))
      : null,
  )
  const [wallet, setWallet] = useState(
    Cookies.get(window.location.pathname + 'rigWallet')
      ? parseJson(Cookies.get(window.location.pathname + 'rigWallet'))
      : null,
  )
  const [preset, setPreset] = useState(
    Cookies.get(window.location.pathname + 'rigPreset')
      ? parseJson(Cookies.get(window.location.pathname + 'rigPreset'))
      : null,
  )
  const [pool, setPool] = useState(
    Cookies.get(window.location.pathname + 'rigPool')
      ? parseJson(Cookies.get(window.location.pathname + 'rigPool'))
      : null,
  )
  const [miner, setMiner] = useState(
    Cookies.get(window.location.pathname + 'rigMiner')
      ? parseJson(Cookies.get(window.location.pathname + 'rigMiner'))
      : null,
  )
  const [minHashrate, setMinHashrate] = useState(
    Cookies.get(window.location.pathname + 'minHashrate') ? Cookies.get(window.location.pathname + 'minHashrate') : '',
  )
  const [maxHashrate, setMaxHashrate] = useState(
    Cookies.get(window.location.pathname + 'maxHashrate') ? Cookies.get(window.location.pathname + 'maxHashrate') : '',
  )
  const [minTemp, setMinTemp] = useState('')
  const [minFilterTemp, setMinFilterTemp] = useState(
    Cookies.get(window.location.pathname + 'minTemp') ? Cookies.get(window.location.pathname + 'minTemp') : '',
  )
  const [maxFilterTemp, setMaxFilterTemp] = useState(
    Cookies.get(window.location.pathname + 'maxTemp') ? Cookies.get(window.location.pathname + 'maxTemp') : '',
  )
  const [maxTemp, setMaxTemp] = useState('')
  const [lowHashrate, setLowHashrate] = useState(
    Cookies.get(window.location.pathname + 'lowHashrate')
      ? Cookies.get(window.location.pathname + 'lowHashrate') === 'true'
      : false,
  )
  const [rejectedShares, setRejectedShares] = useState(
    Cookies.get(window.location.pathname + 'rejectedShares')
      ? Cookies.get(window.location.pathname + 'rejectedShares') === 'true'
      : false,
  )
  const [stopError, setStopError] = useState(
    Cookies.get(window.location.pathname + 'stopError')
      ? Cookies.get(window.location.pathname + 'stopError') === 'true'
      : false,
  )
  const [rigAlerts, setRigAlerts] = useState(
    Cookies.get(window.location.pathname + 'rigAlerts')
      ? Cookies.get(window.location.pathname + 'rigAlerts') === 'true'
      : false,
  )

  const [hotGPU, setHotGPU] = useState(
    Cookies.get(window.location.pathname + 'hotGPU')
      ? Cookies.get(window.location.pathname + 'hotGPU') === 'true'
      : false,
  )

  const [problemGPU, setProblemGPU] = useState(
    Cookies.get(window.location.pathname + 'problemGPU')
      ? Cookies.get(window.location.pathname + 'problemGPU') === 'true'
      : false,
  )

  const [gpuType, setGpuType] = useState(
    Cookies.get(window.location.pathname + 'gpuType')
      ? parseJson(Cookies.get(window.location.pathname + 'gpuType'))
      : 'all',
  )

  const [modalShow, setModalShow] = useState(false)
  const [dopFilters, setDopFilters] = useState({ setButton: false, filtering: false })
  const [coinsForDopFiters, setCoinsForDopFiters] = useState([])
  const [walletsForDopFiters, setWalletsForDopFiters] = useState([])
  const [poolsForDopFiters, setPoolsForDopFiters] = useState([])
  const [mpsForDopFiters, setMpsForDopFiters] = useState([])
  const [minersForDopFiters, setMinersForDopFiters] = useState([])
  const [tempMinDopFilter, setTempMinDopFilter] = useState()
  const [tempMaxDopFilter, setTempMaxDopFilter] = useState()
  const [rejectedSharesDopFilter, setRejectedSharesDopFilter] = useState(false)

  const toggleModal = useCallback(status => {
    setModalShow(status)
    setDelay(10000)
  }, [])

  const statusOptions = [
    { value: 'all', label: t('pages.workers.all') },
    { value: 'online', label: t('pages.workers.online') },
    { value: 'offline', label: t('pages.workers.offline') },
    { value: 'haveAlerts', label: t('pages.workers.problem') },
    { value: 'amd', label: t('pages.workers.gpuTypeAmd') },
    { value: 'nvidia', label: t('pages.workers.gpuTypeNvidia') },
    { value: 'offlineCards', label: t('pages.workers.gpuTypeOffline') },
    { value: 'not_detected', label: t('pages.workers.gpuTypeNotDetected') },
  ]
  const groupOptions = [{ value: 'all', label: t('pages.workers.all') }]

  // dop filters
  useEffect(() => {
    if (dopFilters.setButton || dopFilters.filtering) {
      setDopFilters({ ...dopFilters, setButton: false })
      const coinsArr = []
      const walletsIdsArr = []
      const poolsIdsArr = []
      const mpsIdsArr = []
      const minersIdsArr = []

      if (coin && coin.length > 0) {
        for (const el of coin) {
          coinsArr.push(el.value)
        }
      }
      if (wallet && wallet.length > 0) {
        for (const el of wallet) {
          walletsIdsArr.push(el.id)
        }
      }
      if (pool && pool.length > 0) {
        for (const el of pool) {
          poolsIdsArr.push(el.value)
        }
      }
      if (preset && preset.length > 0) {
        for (const el of preset) {
          mpsIdsArr.push(el.value)
        }
      }
      if (miner && miner.length > 0) {
        for (const el of miner) {
          minersIdsArr.push(el.id)
        }
      }
      setCoinsForDopFiters(coinsArr)
      setWalletsForDopFiters(walletsIdsArr)
      setPoolsForDopFiters(poolsIdsArr)
      setMinersForDopFiters(minersIdsArr)
      setMpsForDopFiters(mpsIdsArr)
      setTempMaxDopFilter(maxFilterTemp)
      setTempMinDopFilter(minFilterTemp)
      setRejectedSharesDopFilter(rejectedShares)

      setDopFilterRigsList({
        page: currentPage,
        size: showCount,
        orderBy,
        orderDirection,
        favoriteFirst,
        status: status.value,
        dc,
        coins: coinsArr.toString(),
        walletsIds: walletsIdsArr.toString(),
        poolsIds: poolsIdsArr.toString(),
        mpsIds: mpsIdsArr.toString(),
        minersIds: minersIdsArr.toString(),
        tempMax: +maxFilterTemp,
        tempMin: +minFilterTemp,
        rejectedShares,
      })
    }
  }, [dopFilters.setButton, currentPage, showCount, orderBy, orderDirection, favoriteFirst])

  // download data in start, pagination, filter
  useEffect(() => {
    if (!dopFilters.filtering) {
      if (search) {
        setRigsList({
          page: currentPage,
          size: showCount,
          orderBy,
          orderDirection,
          favoriteFirst,
          search,
        })
      } else if (status.value === 'online') {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            online: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            online: true,
          })
      } else if (status.value === 'offline' && !dopFilters.setButton) {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            offline: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            offline: true,
          })
      } else if (status.value === 'haveAlerts') {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            haveAlerts: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            haveAlerts: true,
          })
      } else if (status.value === 'amd') {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            amdGPU: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            amdGPU: true,
          })
      } else if (status.value === 'nvidia') {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            nvidiaGPU: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            nvidiaGPU: true,
          })
      } else if (status.value === 'offlineCards') {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            offlineGPU: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            offlineGPU: true,
          })
      } else if (status.value === 'not_detected') {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            notDetectedGPU: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            notDetectedGPU: true,
          })
      } else if (problemGPU) {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            otherProblemsGPU: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            otherProblemsGPU: true,
          })
      } else if (hotGPU) {
        if (dc && dc.value !== 'all')
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            highTempGPU: true,
            dc,
          })
        else
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            highTempGPU: true,
          })
      } else if (maxTemp) {
        if (dc && dc.value !== 'all')
          setFilterRigsList({ page: 1, size: showCount, orderBy, orderDirection, favoriteFirst, maxTemp: true, dc })
        else setFilterRigsList({ page: 1, size: showCount, orderBy, orderDirection, favoriteFirst, maxTemp: true })
      } else if (minTemp) {
        if (dc && dc.value !== 'all')
          setFilterRigsList({ page: 1, size: showCount, orderBy, orderDirection, favoriteFirst, minTemp: true, dc })
        else setFilterRigsList({ page: 1, size: showCount, orderBy, orderDirection, favoriteFirst, minTemp: true })
      } else if (
        dc &&
        dc.value !== 'all' &&
        !minTemp &&
        !maxTemp &&
        !hotGPU &&
        !problemGPU &&
        status.value !== 'online' &&
        status.value !== 'offline' &&
        status.value !== 'haveAlerts' &&
        status.value !== 'amd' &&
        status.value !== 'nvidia' &&
        status.value !== 'offlineCards' &&
        status.value !== 'not_detected'
      ) {
        setFilterRigsList({ page: currentPage, size: showCount, orderBy, orderDirection, favoriteFirst, dc })
      } else {
        setRigsList({ page: currentPage, size: showCount, orderBy, orderDirection, favoriteFirst })
      }
      setRigsData()
    }
  }, [
    setRigsList,
    currentPage,
    showCount,
    orderBy,
    orderDirection,
    favoriteFirst,
    search,
    status,
    problemGPU,
    hotGPU,
    maxTemp,
    minTemp,
    dc,
  ])

  useEffect(() => {
    setRoomsList({ page: 1, size: 1000 })
    setMiningPresetList({ page: 1, size: 1000, filters: {} })
    setPoolsList({ page: 1, size: 1000 })
    setMinersList({ page: 1, size: 1000 })
    setWalletsList({ page: 1, size: 1000 })
    setAlgoCoinsList({ page: 1, size: 1000 })
    setSlotsList({ page: 1, size: 1000 })
  }, [setMinersList, setMiningPresetList, setPoolsList, setRoomsList, setWalletsList])

  useEffect(() => {
    const optionsArray = [{ value: 'all', label: t('pages.workers.all') }]
    dcManagement.dataCentersList.forEach(dc => {
      optionsArray.push({ value: dc.id, label: dc.name })
      dc.rooms &&
        dc.rooms.forEach(room => {
          optionsArray.push({
            value: 'room' + room.id,
            label: '  |---' + room.name,
          })
        })
    })
    setDcOptions(optionsArray)
  }, [dcManagement.dataCentersList, t])

  /*
   * куки с название страницы если совпадает то оставить прежний номер страницы
   * */
  const [delay, setDelay] = useState(10000)

  //update rigs shares
  useEffect(() => {
    const rigInfo = setInterval(() => {
      if (!isRigsListDownload) {
        if (dopFilters.filtering) {
          setDopFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            status: status.value,
            dc,
            coins: coinsForDopFiters.toString(),
            walletsIds: walletsForDopFiters.toString(),
            mpsIds: mpsForDopFiters.toString(),
            minersIds: minersForDopFiters.toString(),
            poolsIds: poolsForDopFiters.toString(),
            rejectedShares: rejectedSharesDopFilter,
            tempMin: +tempMinDopFilter,
            tempMax: +tempMaxDopFilter,
            type: '10sec',
          })
          return
        }

        if (search) {
          setRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            type: '10sec',
            search,
          })
          return
        }

        if (status.value === 'online') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              online: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              online: true,
            })
          return
        }

        if (status.value === 'offline') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              offline: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              offline: true,
            })
          return
        }

        if (status.value === 'haveAlerts') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              haveAlerts: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              haveAlerts: true,
            })
          return
        }

        if (status.value === 'amd') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              amdGPU: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              amdGPU: true,
            })
          return
        }

        if (status.value === 'nvidia') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              nvidiaGPU: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              nvidiaGPU: true,
            })
          return
        }

        if (status.value === 'offlineCards') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              offlineGPU: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              offlineGPU: true,
            })
          return
        }

        if (status.value === 'not_detected') {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              notDetectedGPU: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              notDetectedGPU: true,
            })
          return
        }

        if (problemGPU) {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              otherProblemsGPU: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              otherProblemsGPU: true,
            })
          return
        }

        if (hotGPU) {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              highTempGPU: true,
              dc,
            })
          else
            setFilterRigsList({
              page: currentPage,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              highTempGPU: true,
            })
          return
        }

        if (maxTemp) {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: 1,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              maxTemp: true,
              dc,
            })
          else
            setFilterRigsList({
              page: 1,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              maxTemp: true,
            })
          return
        }

        if (minTemp) {
          if (dc && dc.value !== 'all')
            setFilterRigsList({
              page: 1,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              minTemp: true,
              dc,
            })
          else
            setFilterRigsList({
              page: 1,
              size: showCount,
              orderBy,
              orderDirection,
              favoriteFirst,
              type: '10sec',
              minTemp: true,
            })
          return
        }

        if (dc && dc.value !== 'all') {
          setFilterRigsList({
            page: currentPage,
            size: showCount,
            orderBy,
            orderDirection,
            favoriteFirst,
            type: '10sec',
            dc,
          })
          return
        }

        setRigsList({
          page: currentPage,
          size: showCount,
          orderBy,
          orderDirection,
          favoriteFirst,
          type: '10sec',
        })
      }
      return
    }, delay)

    if (modalShow) clearInterval(rigInfo)
    return () => {
      clearInterval(rigInfo)
    }
  }, [
    modalShow,
    isRigsListDownload,
    setRigsList,
    currentPage,
    orderBy,
    orderDirection,
    favoriteFirst,
    showCount,
    search,
    status,
    minTemp,
    maxTemp,
    dc,
    dopFilters.filtering,
    coinsForDopFiters,
    walletsForDopFiters,
    poolsForDopFiters,
    mpsForDopFiters,
    minersForDopFiters,
    tempMinDopFilter,
    tempMaxDopFilter,
    rejectedSharesDopFilter,
  ])

  //update rigs total data
  useEffect(() => {
    if (modalShow) return
    const rigsInfo = setInterval(() => {
      setRigsData()
      return
    }, 15000)

    return () => {
      clearInterval(rigsInfo)
    }
  }, [modalShow, setRigsData])

  useEffect(() => {
    visibleWorkerActions(allRigs)
  }, [allRigs, visibleWorkerActions])

  const checkFunc = row => {
    const existRig = allRigs.find(rig => rig.id === row.id)
    const rigActions = []
    rigsList.forEach(rig => {
      if (rig.id === row.id) {
        rigActions.push(rig)
      }
    })

    //if there is no match with the list of rigs
    if (!existRig) {
      const needArr = [...allRigs, ...rigActions]
      setAllRigs(needArr)
    }
    //if there are matches, then you need to delete
    else {
      const deleteRigId = allRigs.filter(rig => rig.id !== row.id)
      setAllRigs(deleteRigId)
    }
  }

  const watchForWorker = rig => {
    setRigMinerAction({
      rigId: rig.id,
      backLight: !rig.backLight,
    })
  }

  const checkAllFunc = rows => {
    if (!rows.length) {
      setAllRigs([])
    } else {
      const rigActionsAdd = []
      const rigActionsDelete = []
      rows.forEach(row => {
        rigsList.forEach(rig => {
          if (rig.id === row.id) {
            allRigs.find(rig => rig.id === row.id) ? rigActionsDelete.push(rig) : rigActionsAdd.push(rig)
          }
        })
      })
      const needArr = [...allRigs, ...rigActionsAdd]
      const deleteRigId = needArr.filter(rig => !rigActionsDelete.find(rigDelete => rigDelete.id === rig.id))
      setAllRigs(deleteRigId)
    }
  }

  let gpu_consumption_visible = 0
  let card_count_visible = 0
  let card_count_nvidia_visible = 0
  let card_count_amd_visible = 0
  let card_count_offline_visible = 0
  let rig_count_visible = 0
  let rig_count_offline_visible = 0
  let rig_count_alert_visible = 0
  let max_temp_visible = 0
  let avg_temp_visible = 0
  let min_temp_visible = 0
  let min_temp_cycle_visible = 1000
  const algorithms_visible = []
  let hot_cards_visible = 0
  let problem_cards_visible = 0
  let not_detected_cards_visible = 0
  let maxTempRigVisible = 0

  const cardsStatsVisible = (rig = {}) => {
    rig.videoCards.forEach(card => {
      if (!card.detected) {
        if (rig.active) not_detected_cards_visible++
        if (!rig.active) card_count_offline_visible++
        card.cardType === 'nvidia' ? card_count_nvidia_visible++ : card_count_amd_visible++
        return false
      }
      if (card.highTemp && card.critTemp) hot_cards_visible++
      //if (card.invalidShares > 0) bad_block_count += +card.invalidShares
      if (card.badBlocsNumber > 0 || card.invalidShares > 0) problem_cards_visible++
      card.cardType === 'nvidia' ? card_count_nvidia_visible++ : card_count_amd_visible++
      gpu_consumption_visible += +card.powerInfo
      avg_temp_visible += +card.gpuTemp
      if (+card.gpuTemp > max_temp_visible) max_temp_visible = +card.gpuTemp
      if (+card.gpuTemp < min_temp_cycle_visible && +card.gpuTemp > 0) min_temp_cycle_visible = +card.gpuTemp
      if (+card.gpuTemp > maxTempRigVisible) maxTempRigVisible = +card.gpuTemp
      if (+card.highTemp) rig.highTemp = true
      if (+card.critTemp) {
        rig.criticalTemp = true
        rig.highTemp = false
      }
      card_count_visible++
      if (!rig.active) card_count_offline_visible++
    })
  }

  if (allRigs.length > 0) {
    allRigs.forEach(rig => {
      if (!rig.active) rig_count_offline_visible++
      if (rig.gpuErr > 0 || rig.lowHash || rig.rebootNum > 0 || rig.gpuRej > 0 || rig.action === 'stopError')
        rig_count_alert_visible++
      if (rig.active && rig.gpuTotalSpeed > 0) {
        if (rig.algo) {
          if (!algorithms_visible.find(algo => algo.name === rig.algo)) {
            const coin = coinsList.find(el => el.nameSymbol === rig.coin)
            if (rig.coin === 'DNX' && coin) coin.units = 'kH/s'
            algorithms_visible.push({ name: rig.algo, value: 0, unit: coin ? coin.units : 'mg/s' })
          }
          algorithms_visible.find(algo => algo.name === rig.algo).value += +rig.gpuTotalSpeed
        }
      }

      rig.highTemp = false
      rig.criticalTemp = false

      if (rig.videoCards) {
        cardsStatsVisible(rig)
      }
      rig_count_visible++
    })
  } else {
    visibleRigs.forEach(rig => {
      if (!rig.active) rig_count_offline_visible++
      if (rig.errorsTotal > 0) rig_count_alert_visible++
      if (rig.active && rig.gpuTotalSpeed > 0) {
        if (rig.algo) {
          if (!algorithms_visible.find(algo => algo.name === rig.algo)) {
            const coin = coinsList.find(el => el.nameSymbol === rig.coin)
            if (rig.coin === 'DNX' && coin) coin.units = 'kH/s'
            algorithms_visible.push({ name: rig.algo, value: 0, unit: coin ? coin.units : 'mg/s' })
          }
          algorithms_visible.find(algo => algo.name === rig.algo).value += +rig.gpuTotalSpeed
        }
      }

      rig.highTemp = false
      rig.criticalTemp = false

      if (rig.videoCards) {
        cardsStatsVisible(rig)
      }
      rig_count_visible++
    })
  }

  if (rigsList && rigsList.length > 0 && (allRigs.length > 0 || visibleRigs.length > 0))
    if (card_count_visible > 0) {
      avg_temp_visible = avg_temp_visible / card_count_visible
    } else {
      avg_temp_visible = 0
    }

  if (min_temp_cycle_visible < 1000) min_temp_visible = min_temp_cycle_visible

  const applyFilters = (filters = {}) => {
    setCurrentPage(1)
    Cookies.set(window.location.pathname + 'dataTablecurrentPage', 1, { expires: 30 })
    setDc(filters.dc)
    setCoin(filters.coin)
    setWallet(filters.wallet)
    setPreset(filters.preset)
    setPool(filters.pool)
    setMiner(filters.miner)
    setMinHashrate(filters.minHashrate)
    setMaxHashrate(filters.maxHashrate)
    setMinFilterTemp(filters.minTemp)
    setMaxFilterTemp(filters.maxTemp)

    setLowHashrate(filters.lowHashrate)
    setRejectedShares(filters.rejectedShares)
    setStopError(filters.stopError)
    setDopFilters({ filtering: true, setButton: true })
  }

  const resetFilters = () => {
    setCurrentPage(1)
    Cookies.set(window.location.pathname + 'dataTablecurrentPage', 1, { expires: 30 })
    setStatus({ value: 'all', label: t('pages.workers.all') })
    Cookies.set(
      window.location.pathname + 'rigStatus',
      JSON.stringify({ value: 'all', label: t('pages.workers.all') }),
      { expires: 30 },
    )
    setDc({ value: 'all', label: t('pages.workers.all') })
    Cookies.set(window.location.pathname + 'rigDC', JSON.stringify({ value: 'all', label: t('pages.workers.all') }), {
      expires: 30,
    })
    setFavoriteFirst(false)
    Cookies.set(window.location.pathname + 'favoriteFirst', JSON.stringify(false), { expires: 30 })

    setCoin(null)
    Cookies.set(window.location.pathname + 'rigCoin', JSON.stringify(null), {
      expires: 30,
    })
    setWallet(null)
    Cookies.set(window.location.pathname + 'rigWallet', JSON.stringify(null), {
      expires: 30,
    })

    setPreset(null)
    Cookies.set(window.location.pathname + 'rigPreset', JSON.stringify(null), {
      expires: 30,
    })

    setPool(null)
    Cookies.set(window.location.pathname + 'rigPool', JSON.stringify(null), {
      expires: 30,
    })

    setMiner(null)
    Cookies.set(window.location.pathname + 'rigMiner', JSON.stringify(null), {
      expires: 30,
    })

    setMinHashrate('')
    Cookies.set(window.location.pathname + 'minHashrate', '', { expires: 30 })

    setMaxHashrate('')
    Cookies.set(window.location.pathname + 'maxHashrate', '', { expires: 30 })

    setMinFilterTemp('')
    Cookies.set(window.location.pathname + 'minTemp', '', { expires: 30 })

    setMaxTemp('')
    setMinTemp('')

    setMaxFilterTemp('')
    Cookies.set(window.location.pathname + 'maxTemp', '', { expires: 30 })

    setLowHashrate(false)
    Cookies.set(window.location.pathname + 'lowHashrate', JSON.stringify(false), { expires: 30 })

    setRejectedShares(false)
    Cookies.set(window.location.pathname + 'rejectedShares', JSON.stringify(false), { expires: 30 })

    setStopError(false)
    Cookies.set(window.location.pathname + 'stopError', JSON.stringify(false), {
      expires: 30,
    })
    setRigAlerts(false)
    Cookies.set(window.location.pathname + 'rigAlerts', JSON.stringify(false), {
      expires: 30,
    })

    setHotGPU(false)
    Cookies.set(window.location.pathname + 'hotGPU', JSON.stringify(false), {
      expires: 30,
    })

    setProblemGPU(false)
    Cookies.set(window.location.pathname + 'problemGPU', JSON.stringify(false), { expires: 30 })

    setGpuType('all')
    Cookies.set(window.location.pathname + 'gpuType', JSON.stringify('all'), {
      expires: 30,
    })
    setDopFilters({ filtering: false, setButton: false })
    setUncheckAll(!uncheckAll)
  }

  // useState setWorkerList
  useEffect(() => {
    const now = new Date().getTime()
    const workersArray = rigsList.map(rig => {
      const checkMiningTime = now - Date.parse(rig.updatedAt)
      if (!rig.gpuRej || rig.gpuRej === 'null') rig.gpuRej = 0
      if (!rig.gpuInv || rig.gpuInv === 'null') rig.gpuInv = 0
      const dualCoin = rig.coinDual ? ` / ${rig.coinDual}` : ''
      const checkOverclocking = rig.videoCards ? rig.videoCards.find(card => card.newCard === true) : []

      const uptime = () => {
        let str
        if (rig.active && rig.uptime) {
          if (rig.uptime > 0) {
            const days = Math.floor(rig.uptime / 3600 / 24)
            const hours = Math.floor(rig.uptime / 3600) - days * 24
            const min = Math.floor(rig.uptime / 60) - hours * 60 - days * 24 * 60
            str = `${days}${t('pages.workers.d')} ${hours}${t('pages.workers.h')} ${min}${t('pages.workers.m')}`
          }
        } else {
          str = `00${t('pages.workers.d')} 00${t('pages.workers.h')} 00${t('pages.workers.m')}`
        }
        return str
      }

      return {
        ...rig,
        favorite: rig.backLight,
        coin: rig.coin ? rig.coin + dualCoin : t('pages.workers.na'),
        action: (
          <>
            <WorkerActionsMobile
              key={rig.name + rig.rigMac + 'mobile-actions'}
              worker={{
                videoCards: rig.videoCards,
                id: +rig.id,
                miningPresetId: +rig.miningPresetId,
                name: rig.name,
                action: rig.action,
                rigMac: rig.rigMac,
                password: rig.password,
              }}
              setModalShow={toggleModal}
            />
            <WorkerActions
              key={rig.name + rig.rigMac + 'actions'}
              worker={{
                videoCards: rig.videoCards,
                id: +rig.id,
                miningPresetId: +rig.miningPresetId,
                name: rig.name,
                action: rig.action,
                rigMac: rig.rigMac,
                password: rig.password,
              }}
              setModalShow={toggleModal}
            />
          </>
        ),
        uptime: uptime(),
        powerInfo: rig.active && rig.powerInfo > 0 ? rig.powerInfo + 'w' : '0w',

        effShares:
          rig.effShares === 0 || !rig.effShares ? (
            ''
          ) : (
            <span
              key={rig.name + rig.rigMac + 'effShares'}
              style={{
                color: rig.effShares > 85 ? 'inherit' : rig.effShares < 61 ? 'red' : 'yellow',
              }}
            >
              {rig.effShares}%
            </span>
          ),
        miner:
          rig.active && rig.downloadMiner ? (
            <div key={rig.name + rig.rigMac + 'miner'}>
              <i className='fa fa-spinner fa-pulse fa-1x fa-fw' style={{ color: 'green', margin: '8px' }}></i>
            </div>
          ) : rig.miner ? (
            <div>
              {rig.secondMiner ? (
                <div>
                  <div>{rig.miner}</div>
                  <div>{rig.secondMiner}</div>
                </div>
              ) : (
                rig.miner
              )}
            </div>
          ) : t('pages.workers.na') + rig.minerDual && rig.minerDual > 0 ? (
            ' / ' + `${rig.secondMiner ? rig.secondMiner : rig.minerDual}`
          ) : null,
        status: rig.rigMac && (
          <div className={'worker_statuses'} key={rig.name + rig.rigMac + 'status'}>
            {rig.rebootNum > 0 && (
              <span tooltip={t('pages.workers.tooManyReboots')}>
                <i className='fas fa-sync'></i>
              </span>
            )}

            {!rig.active && (
              <span tooltip={t('pages.workers.workerNotConnected')}>
                <i className='far fa-wifi-slash'></i>
              </span>
            )}

            {rig.gpuErr > 0 && rig.gpuErr && (
              <span tooltip={t('pages.workers.checkLogs')}>
                <i className='fas fa-info-circle'></i>
                <div>{rig.gpuErr}</div>
              </span>
            )}

            {rig.active && rig.action !== 'stop' && rig.errorsTotal > 0 && (
              <span
                className='rig-errors'
                tooltip={
                  t('pages.workers.workerErrors') + ': ' + rig.errorsTotal + ' ' + t('pages.workers.(checkLogs)')
                }
              >
                <i className='far fa-exclamation-triangle' style={{ color: 'red' }}></i>
              </span>
            )}

            {rig.powerOf && (
              <span tooltip={t('pages.workers.workerPoweredOff')}>
                <i className='fas fa-power-off' style={{ color: 'green' }}></i>
              </span>
            )}

            {rig.active && rig.action === 'run' && rig?.lowHash === 1 && rig.miningPresetId && (
              <span tooltip={t('pages.workers.lowHashrate')}>
                <i className='far fa-chart-line-down' style={{ color: 'yellow' }}></i>
              </span>
            )}
            {rig.active && rig.action === 'run' && rig?.lowHash === 2 && rig.miningPresetId && (
              <span tooltip={t('pages.workers.lowHashrate')}>
                <i className='far fa-chart-line-down' style={{ color: 'red' }}></i>
              </span>
            )}

            {rig.active &&
              rig.action !== 'stop' &&
              rig.action !== 'stopError' &&
              (+rig.gpuRej > 0 || +rig.gpuInv > 0) && (
                <span className='rejected' tooltip={t('pages.workers.rejectedInvalidShares')}>
                  <i className='fas fa-ban'></i>
                  <small>
                    {rig.gpuRej}/{rig.gpuInv}
                  </small>
                </span>
              )}

            {rig.videoCards && rig.videoCards.length > 0 && rig.highTemp && (
              <span tooltip={t('pages.workers.highTemperature')}>
                <i className='fas fa-temperature-high' style={{ color: 'yellow' }}></i>
              </span>
            )}
            {rig.videoCards && rig.videoCards.length > 0 && rig.criticalTemp && (
              <span tooltip={t('pages.workers.criticalTemperature')}>
                <i className='fas fa-burn' style={{ color: 'red' }}></i>
              </span>
            )}

            {rig.active && rig.action === 'stop' && (
              <span tooltip={t('pages.workers.miningStopped')}>
                <i className='far fa-pause-circle' style={{ color: 'green' }}></i>
              </span>
            )}
            {rig.active && rig.action === 'stopError' && (
              <span tooltip={t('pages.workers.miningStopped')}>
                <i className='far fa-pause-circle' style={{ color: 'yellow' }}></i>
              </span>
            )}

            {rig.active && !!checkOverclocking && (
              <span className='rejected' tooltip={t('pages.workers.noOverclocking')}>
                <i className='fas fa-sliders-h' style={{ color: 'yellow' }}></i>
              </span>
            )}

            {rig.active && rig.autofanId && (
              <span className='rejected' tooltip={t('pages.workers.setedAutofan')}>
                <i className='fas fa-fan' style={{ color: 'green' }}></i>
              </span>
            )}

            {/* <span>
                <i className="fas fa-tools"></i>
              </span>

              <span>
                <i className="fas fa-cogs"></i>
              </span> */}
          </div>
        ),
        cards: <WorkerCards key={rig.name + rig.rigMac + 'cards'} rig={rig} checkMiningTime={checkMiningTime} />,
        type: (
          <div style={{ textAlign: 'center' }}>
            {rig.nvidiaCards > 0 && <span style={{ color: 'green' }}>N</span>}
            {rig.amdCards > 0 && <span style={{ color: 'red' }}>A</span>}
          </div>
        ),

        avgFan: rig.active && rig.avgFanValue && rig.avgFanValue !== 'NaN' ? rig.avgFanValue + '%' : '0',
      }
    })
    setWorkersList(workersArray)
  }, [
    rigsList,
    coin,
    wallet,
    preset,
    pool,
    favoriteFirst,
    miner,
    lowHashrate,
    rejectedShares,
    stopError,
    rigAlerts,
    problemGPU,
    hotGPU,
    gpuType,
    presetsList,
    toggleModal,
    t,
  ])

  const workers = {
    columns: [
      {
        label: t('pages.workers.worker'),
        field: 'name',
        sort: true,
        sortType: 'string',
        addToFavorite: true,
        search: true,
      },
      {
        label: t('pages.workers.status'),
        field: 'status',
      },
      {
        label: t('pages.workers.coin'),
        field: 'coin',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.workers.hashrate'),
        field: 'hashRate',
        sort: true,
        sortType: 'int',
        sortByValue: true,
      },
      {
        label: t('pages.workers.health'),
        field: 'cards',
      },
      {
        label: t('pages.workers.uptime'),
        field: 'uptime',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.workers.workerPower'),
        field: 'powerInfo',
        sort: true,
        sortType: 'int',
        sortByValue: true,
      },
      {
        label: t('pages.workers.cardFan'),
        field: 'avgFan',
        sort: true,
        sortType: 'int',
        sortByValue: true,
      },
      {
        label: t('pages.workers.effShares'),
        field: 'effShares',
        sort: true,
        sortType: 'int',
        sortByValue: true,
      },
      {
        label: t('pages.workers.miner'),
        field: 'miner',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.workers.preset'),
        field: 'miningPresetName',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.workers.HW'),
        field: 'type',
        sort: true,
        sortType: 'string',
        sortByValue: true,
      },

      {
        label: t('pages.workers.action'),
        field: 'action',
      },
    ],
    rows: workersList,
  }

  const [isVisible, setIsVisible] = useState([false, 'none'])

  useEffect(() => {
    // Show button when user scrolls down 20px from the top of the document
    function handleScroll() {
      if (window.pageYOffset > 20) {
        setIsVisible([true, 'block'])
      } else {
        setIsVisible([false, 'none'])
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Scroll to the top of the document when the button is clicked
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className={'workers_page'}>
          <div className={'workers_header'}>
            <Row>
              <Col lg={12} xl={6}>
                <div className={'workers_header__info'}>
                  <div className={'workers_header__info_header'}>{t('pages.workers.total')}</div>
                  <div
                    className={'workers_header__info_parts'}
                    style={!rigsStat.isDownloadRigsStats ? { opacity: '0.5' } : {}}
                  >
                    {!rigsStat.isDownloadRigsStats ? <Loader /> : ''}
                    <div className={'workers_header__info_part'}>
                      <div className={'workers_header__info_row workers_header__info_dc_room'}>
                        <div>
                          <span>{t('pages.workers.DC')}:</span>&nbsp;
                          {dcManagement.totalDC}
                        </div>
                        <div>
                          <span>{t('pages.workers.rooms')}:</span>&nbsp;
                          {dcManagement.totalRooms}
                        </div>
                      </div>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.GPUTooltip')}>
                        <div>
                          <span className={'workers_header__info_icon'}>
                            <i className='fad fa-bolt'></i>
                          </span>
                          {rigsStat.gpu_consumption > 1000
                            ? (rigsStat.gpu_consumption / 1000).toFixed(2) + 'kW'
                            : rigsStat.gpu_consumption.toFixed(0) + 'W'}
                          {/* /&nbsp;
                            {rigsStat.gpu_consumption > 1000
                              ? (rigsStat.gpu_consumption / 1000).toFixed(2) + 'kW'
                              : rigsStat.gpu_consumption.toFixed(0) + 'W'} */}
                        </div>
                      </div>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.GPUTempTooltip')}>
                        <div>
                          <span className={'workers_header__info_icon'}>
                            <i className='fas fa-thermometer-three-quarters'></i>
                          </span>{' '}
                          <span
                            onClick={() => {
                              resetFilters()
                              setMinTemp(rigsStat.min_temp.toFixed(0))
                              setMinFilterTemp(rigsStat.min_temp.toFixed(0))
                              Cookies.set(window.location.pathname + 'minTemp', rigsStat.min_temp, { expires: 30 })
                            }}
                            style={{ color: '#626ed4', cursor: 'pointer' }}
                          >
                            {rigsStat.min_temp.toFixed(0)}
                          </span>
                          /&nbsp;<span>{rigsStat.avg_temp.toFixed(0)}</span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setMaxTemp(rigsStat.max_temp.toFixed(0))
                              setMaxFilterTemp(rigsStat.max_temp.toFixed(0))
                              Cookies.set(window.location.pathname + 'minTemp', rigsStat.max_temp, { expires: 30 })
                            }}
                            style={{ color: '#ff6666', cursor: 'pointer' }}
                          >
                            {rigsStat.max_temp.toFixed(0)}
                          </span>
                          &#8451;
                        </div>
                      </div>
                    </div>
                    <div className={'workers_header__info_part'}>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.workersTooltip')}>
                        <div>
                          <div>
                            <span
                              className={
                                'workers_header__info_icon workers_header__info_icon_svg workers_header__info_icon_svg_rig'
                              }
                            ></span>
                          </div>{' '}
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({
                                value: 'all',
                                label: t('pages.workers.all'),
                              })
                              Cookies.set(
                                window.location.pathname + 'rigStatus',
                                JSON.stringify({
                                  value: 'all',
                                  label: t('pages.workers.all'),
                                }),
                                { expires: 30 },
                              )
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            {rigsStat.rig_count}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'online', label: 'Online' })
                              Cookies.set(
                                window.location.pathname + 'rigStatus',
                                JSON.stringify({
                                  value: 'all',
                                  label: t('pages.workers.all'),
                                }),
                                { expires: 30 },
                              )
                            }}
                            style={{ color: 'green', cursor: 'pointer' }}
                          >
                            {rigsStat.rig_count - rigsStat.rig_count_offline}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'haveAlerts', label: 'Have Alerts Cards' })
                              Cookies.set(window.location.pathname + 'rigAlerts', JSON.stringify(true), { expires: 30 })
                            }}
                            style={{ color: '#ff6666', cursor: 'pointer' }}
                          >
                            {rigsStat.rig_count_alert}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'offline', label: 'Offline' })
                              Cookies.set(
                                window.location.pathname + 'rigStatus',
                                JSON.stringify({
                                  value: 'all',
                                  label: t('pages.workers.all'),
                                }),
                                { expires: 30 },
                              )
                            }}
                            style={{ color: '#afafaf', cursor: 'pointer' }}
                          >
                            {rigsStat.rig_count_offline}
                          </span>
                        </div>
                      </div>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.cardsTooltip')}>
                        <div>
                          <div>
                            <span
                              className={
                                'workers_header__info_icon workers_header__info_icon_svg workers_header__info_icon_svg_gpu'
                              }
                            ></span>
                          </div>
                          <span
                            onClick={() => {
                              resetFilters()
                              setGpuType('all')
                              Cookies.set(window.location.pathname + 'gpuType', JSON.stringify('all'), { expires: 30 })
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            {rigsStat.card_count}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'nvidia', label: 'Nvidia Cards' })
                              Cookies.set(window.location.pathname + 'gpuType', JSON.stringify('nvidia'), {
                                expires: 30,
                              })
                            }}
                            style={{ color: 'green', cursor: 'pointer' }}
                          >
                            {rigsStat.card_count_nvidia}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'amd', label: 'Amd Cards' })
                              Cookies.set(window.location.pathname + 'gpuType', JSON.stringify('amd'), { expires: 30 })
                            }}
                            style={{ color: '#ff6666', cursor: 'pointer' }}
                          >
                            {rigsStat.card_count_amd}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'offlineCards', label: 'Cards Offline' })
                              Cookies.set(window.location.pathname + 'gpuType', JSON.stringify('offline'), {
                                expires: 30,
                              })
                            }}
                            style={{ color: '#afafaf', cursor: 'pointer' }}
                          >
                            {rigsStat.card_count_offline}
                          </span>
                        </div>
                      </div>
                      <div className={'workers_header__info_row workers_header__info_dc_room'}>
                        <div tooltip={t('pages.workers.cardProblemsTooltip')}>
                          <span className={'workers_header__info_icon'}>
                            <i className='fas fa-exclamation-triangle'></i>
                          </span>
                          <span
                            onClick={() => {
                              resetFilters()
                              setHotGPU(true)
                              Cookies.set(window.location.pathname + 'hotGPU', JSON.stringify(true), { expires: 30 })
                            }}
                            style={{ color: '#ff6666', cursor: 'pointer' }}
                          >
                            {rigsStat.hot_cards}
                          </span>
                          /&nbsp;
                          <span
                            onClick={() => {
                              resetFilters()
                              setProblemGPU(true)
                              Cookies.set(window.location.pathname + 'problemGPU', JSON.stringify(true), {
                                expires: 30,
                              })
                            }}
                            style={{
                              color: 'rgb(255 165 54)',
                              cursor: 'pointer',
                            }}
                          >
                            {rigsStat.problem_cards}
                          </span>
                        </div>
                        <div tooltip={t('pages.workers.notDetectedGPUs')}>
                          <span className={'workers_header__info_icon'}>
                            <i className='fas fa-puzzle-piece'></i>
                          </span>
                          <span
                            onClick={() => {
                              resetFilters()
                              setStatus({ value: 'not_detected', label: 'Not Detected Cards' })
                              Cookies.set(window.location.pathname + 'gpuType', JSON.stringify('not_detected'), {
                                expires: 30,
                              })
                            }}
                            style={{ color: '#ff6666', cursor: 'pointer' }}
                          >
                            {rigsStat.not_detected_cards}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={'workers_header__info_part workers_header__info_part_algo'}>
                      {rigsStat.algorithms.map(algo => {
                        return (
                          <div className={'workers_header__info_row'} key={uuid()}>
                            <div>
                              <span>{algo.name}: </span>{' '}
                              {algo.value
                                ? algo.value > 999999
                                  ? +(algo.value / 1000000).toFixed(3)
                                  : algo.value > 999
                                  ? +(algo.value / 1000).toFixed(2)
                                  : +algo.value
                                : ''}
                              {algo.value ? ` ${algo.unit}` : ''}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12} xl={6} className={'d-none d-md-block'}>
                <div className={'workers_header__info'}>
                  <div className={'workers_header__info_header'}>
                    {allRigs.length > 0 ? 'Selected' : visibleRigs.length > 0 ? 'Visible' : 'Visible'}
                  </div>
                  <div className={'workers_header__info_parts'}>
                    <div className={'workers_header__info_part'}>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.GPUTooltip')}>
                        <div>
                          <span className={'workers_header__info_icon'}>
                            <i className='fad fa-bolt'></i>
                          </span>
                          {gpu_consumption_visible > 1000
                            ? (gpu_consumption_visible / 1000).toFixed(2) + 'kW'
                            : gpu_consumption_visible.toFixed(0) + 'W'}
                          /&nbsp;
                          {gpu_consumption_visible > 1000
                            ? (gpu_consumption_visible / 1000).toFixed(2) + 'kW'
                            : gpu_consumption_visible.toFixed(0) + 'W'}
                        </div>
                      </div>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.GPUTempTooltip')}>
                        <div>
                          <span className={'workers_header__info_icon'}>
                            <i className='fas fa-thermometer-three-quarters'></i>
                          </span>{' '}
                          <span style={{ color: '#626ed4' }}>{min_temp_visible.toFixed(0)}</span>
                          /&nbsp;<span>{avg_temp_visible.toFixed(0)}</span>
                          /&nbsp;
                          <span style={{ color: '#ff6666' }}>{max_temp_visible.toFixed(0)}</span>
                          &#8451;
                        </div>
                      </div>
                    </div>
                    <div className={'workers_header__info_part'}>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.workersTooltip')}>
                        <div>
                          <div>
                            <span
                              className={
                                'workers_header__info_icon workers_header__info_icon_svg workers_header__info_icon_svg_rig'
                              }
                            ></span>
                          </div>{' '}
                          {rig_count_visible}/&nbsp;
                          <span style={{ color: 'green' }}>{rig_count_visible - rig_count_offline_visible}</span>
                          /&nbsp;
                          <span style={{ color: '#ff6666' }}>{rig_count_alert_visible}</span>
                          /&nbsp;
                          <span style={{ color: '#afafaf' }}>{rig_count_offline_visible}</span>
                        </div>
                      </div>
                      <div className={'workers_header__info_row'} tooltip={t('pages.workers.cardsTooltip')}>
                        <div>
                          <div>
                            <span
                              className={
                                'workers_header__info_icon workers_header__info_icon_svg workers_header__info_icon_svg_gpu'
                              }
                            ></span>
                          </div>
                          <span>{card_count_visible}</span>/&nbsp;
                          <span style={{ color: 'green' }}>{card_count_nvidia_visible}</span>
                          /&nbsp;
                          <span style={{ color: '#ff6666' }}>{card_count_amd_visible}</span>
                          /&nbsp;
                          <span style={{ color: '#afafaf' }}>{card_count_offline_visible}</span>
                        </div>
                      </div>
                      <div className={'workers_header__info_row workers_header__info_dc_room'}>
                        <div tooltip={t('pages.workers.cardProblemsTooltip')}>
                          <span className={'workers_header__info_icon'}>
                            <i className='fas fa-exclamation-triangle'></i>
                          </span>
                          <span style={{ color: '#ff6666' }}>{hot_cards_visible}</span>
                          /&nbsp;
                          <span style={{ color: 'rgb(255 165 54)' }}>{problem_cards_visible}</span>
                        </div>
                        <div tooltip={t('pages.workers.notDetectedGPUs')}>
                          <span className={'workers_header__info_icon'}>
                            <i className='fas fa-puzzle-piece'></i>
                          </span>
                          <span style={{ color: '#ff6666' }}>{not_detected_cards_visible}</span>
                        </div>
                      </div>
                    </div>
                    <div className={'workers_header__info_part workers_header__info_part_algo'}>
                      {algorithms_visible.map(algo => {
                        if (algo.value > 999 && algo.name !== 'dynex') {
                          algo.unit = `k${algo.unit}`
                        }
                        while (+algo.value.toFixed() > 1000 && algo.unit) {
                          if (algo.unit === 'MH/s') algo.unit = 'GH/s'
                          if (algo.unit === 'kH/s') algo.unit = 'MH/s'
                          if (algo.unit === 'h/s') algo.unit = 'kH/s'
                          if (algo.unit === 'sol/s') algo.unit = 'ksol/s'
                          if (algo.unit === 'g/s') algo.unit = 'kg/s'
                          if (algo.unit === 'Gp/s') algo.unit = 'kGp/s'
                          algo.value = +algo.value / 1000
                        }

                        return (
                          <div className={'workers_header__info_row'} key={uuid()}>
                            <div>
                              <span>{algo.name}: </span>{' '}
                              {algo.value > 999999
                                ? +(algo.value / 1000000).toFixed(3)
                                : algo.value > 999
                                ? +(algo.value / 1000).toFixed(3)
                                : +algo.value.toFixed(2)}
                              {algo.value ? ` ${algo.unit}` : ''}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={'workers_body'}>
            <div className={'workers_filters d-none d-md-block'}>
              <Row className={'workers_filters__elements'}>
                <Col sm={4} md={3} className={'workers_filters__element'}>
                  <label>{t('pages.workers.status')}</label>
                  <Select
                    classNamePrefix={'form_control_react_select'}
                    value={status}
                    onChange={option => {
                      setCurrentPage(1)
                      Cookies.set(window.location.pathname + 'dataTablecurrentPage', 1, { expires: 30 })
                      setStatus(option)
                      Cookies.set(window.location.pathname + 'rigStatus', JSON.stringify(option), { expires: 30 })
                    }}
                    options={statusOptions}
                    placeholder={t('pages.workers.all')}
                  />
                </Col>
                <Col sm={4} md={3} className={'workers_filters__element'}>
                  <label>{t('pages.workers.dataCentersFilter')}</label>
                  <Select
                    classNamePrefix={'form_control_react_select'}
                    value={dc}
                    onChange={option => {
                      setCurrentPage(1)
                      Cookies.set(window.location.pathname + 'dataTablecurrentPage', 1, { expires: 30 })
                      setDc(option)
                      Cookies.set(window.location.pathname + 'rigDC', JSON.stringify(option), { expires: 30 })
                    }}
                    options={dcOptions}
                    placeholder={t('pages.workers.all')}
                  />
                </Col>
                <Col sm={4} md={3} className={'workers_filters__element'}>
                  <label>{t('pages.workers.workerGroup')}</label>
                  <Select
                    classNamePrefix={'form_control_react_select'}
                    options={groupOptions}
                    placeholder={t('pages.workers.all')}
                  />
                </Col>
              </Row>
              <div style={{ overflow: showFilters ? 'visible' : 'hidden' }}>
                <div
                  className={'workers_filters__elements__more'}
                  style={{
                    overflow: showFiltersTimer ? 'visible' : 'hidden',
                    maxHeight: showFilters ? '300px' : '0',
                  }}
                >
                  <Row className={'workers_filters__elements'}>
                    <Col sm={4} md={3} className={'workers_filters__element'}>
                      <label>{t('pages.workers.coin')}</label>
                      <SelectWithImages
                        classNamePrefix={'form_control_react_select'}
                        value={coin}
                        onchange={option => {
                          setCoin(option)
                          Cookies.set(window.location.pathname + 'rigCoin', JSON.stringify(option), { expires: 30 })
                        }}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        options={coinsList.map(coin => {
                          return {
                            id: coin.id,
                            icon: coin.nameSymbol,
                            label: coin.nameSymbol,
                            value: coin.nameSymbol,
                          }
                        })}
                        placeholder={t('pages.workers.all')}
                      />
                    </Col>
                    <Col sm={4} md={3} className={'workers_filters__element'}>
                      <label>{t('pages.workers.wallet')}</label>
                      <Select
                        classNamePrefix={'form_control_react_select'}
                        value={wallet}
                        onChange={option => {
                          setWallet(option)
                          Cookies.set(window.location.pathname + 'rigWallet', JSON.stringify(option), { expires: 30 })
                        }}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        options={walletsList.map(wallet => {
                          return {
                            id: wallet.id,
                            value: wallet.address,
                            label: wallet.name,
                          }
                        })}
                        placeholder={t('pages.workers.all')}
                      />
                    </Col>
                    <Col md={4} sm={8} className={'workers_filters__element'}>
                      <Row className={'align-center'}>
                        <Col xs={12} sm={5}>
                          {t('pages.workers.hashrateM')}
                        </Col>
                        <Col xs={12} sm={7} className={'align-center'}>
                          {t('pages.workers.min')}:
                          <Input
                            value={minHashrate}
                            onChange={e => {
                              const re = /^[+-]?\d*(?:[.,]\d*)?$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setMinHashrate(e.target.value)
                                Cookies.set(window.location.pathname + 'minHashrate', e.target.value, { expires: 30 })
                              }
                            }}
                          />
                          &nbsp;&nbsp;{t('pages.workers.max')}:
                          <Input
                            value={maxHashrate}
                            onChange={e => {
                              const re = /^[+-]?\d*(?:[.,]\d*)?$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setMaxHashrate(e.target.value)
                                Cookies.set(window.location.pathname + 'maxHashrate', e.target.value, { expires: 30 })
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={'workers_filters__elements'}>
                    <Col sm={4} md={3} className={'workers_filters__element'}>
                      <label>{t('pages.workers.pool')}</label>
                      <Select
                        classNamePrefix={'form_control_react_select'}
                        value={pool}
                        onChange={option => {
                          setPool(option)
                          Cookies.set(window.location.pathname + 'rigPool', JSON.stringify(option), { expires: 30 })
                        }}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        options={poolsList.map(pool => {
                          return {
                            value: pool.id,
                            label: pool.title,
                          }
                        })}
                        placeholder={t('pages.workers.all')}
                      />
                    </Col>
                    <Col sm={4} md={3} className={'workers_filters__element'}>
                      <label>{t('pages.workers.miningPreset')}</label>
                      <Select
                        classNamePrefix={'form_control_react_select'}
                        value={preset}
                        onChange={option => {
                          setPreset(option)
                          Cookies.set(window.location.pathname + 'rigPreset', JSON.stringify(option), { expires: 30 })
                        }}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        options={presetsList.map(preset => {
                          return {
                            value: preset.id,
                            label: preset.name,
                          }
                        })}
                        placeholder={t('pages.workers.all')}
                      />
                    </Col>
                    <Col md={4} sm={8} className={'workers_filters__element'}>
                      <Row className={'align-center'}>
                        <Col xs={12} sm={5}>
                          {t('pages.workers.temp')}
                        </Col>
                        <Col xs={12} sm={7} className={'align-center'}>
                          {t('pages.workers.min')}:
                          <Input
                            value={minFilterTemp}
                            onChange={e => {
                              const re = /^[+-]?\d*(?:[.,]\d*)?$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setMinFilterTemp(e.target.value)
                                Cookies.set(window.location.pathname + 'minTemp', e.target.value, { expires: 30 })
                              }
                            }}
                          />
                          &nbsp;&nbsp;{t('pages.workers.max')}:
                          <Input
                            value={maxFilterTemp}
                            onChange={e => {
                              const re = /^[+-]?\d*(?:[.,]\d*)?$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setMaxFilterTemp(e.target.value)
                                Cookies.set(window.location.pathname + 'maxTemp', e.target.value, { expires: 30 })
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={'workers_filters__elements'}>
                    <Col sm={4} md={3} className={'workers_filters__element'}>
                      <label>{t('pages.workers.miner')}</label>
                      <Select
                        classNamePrefix={'form_control_react_select'}
                        value={miner}
                        onChange={option => {
                          setMiner(option)
                          Cookies.set(window.location.pathname + 'rigMiner', JSON.stringify(option), { expires: 30 })
                        }}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        options={minersList.map(miner => {
                          return {
                            id: miner.id,
                            value: miner.name,
                            label: miner.name,
                          }
                        })}
                        placeholder={t('pages.workers.all')}
                      />
                    </Col>
                    <Col sm={8} md={7} className={'workers_filters__element'}>
                      <label>{t('pages.workers.troubleshooting')}</label>
                      <div className={'workers_filters__buttons'}>
                        <Button
                          color={rejectedShares ? 'primary' : 'secondary'}
                          onClick={() => {
                            setRejectedShares(!rejectedShares)
                            Cookies.set(window.location.pathname + 'rejectedShares', !rejectedShares, { expires: 30 })
                          }}
                        >
                          <i className='far fa-ban' style={{ color: 'red' }}></i>
                          {t('pages.workers.rejectedShares')}
                        </Button>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setCurrentPage(1)
                            Cookies.set(window.location.pathname + 'dataTablecurrentPage', 1, { expires: 30 })
                            setDopFilters({ filtering: true, setButton: true })
                          }}
                        >
                          {t('pages.workers.apply')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  onClick={() => {
                    setShowFilters(!showFilters)
                    setShowFiltersTimer(false)
                    if (!showFilters)
                      setTimeout(() => {
                        setShowFiltersTimer(true)
                      }, 600)
                  }}
                  className={'workers_more_filters'}
                >
                  {showFilters ? (
                    <div>
                      {t('pages.workers.hide')} <i style={{ color: '#626ed4' }} className='fas fa-angle-double-up'></i>
                    </div>
                  ) : (
                    <div>
                      {t('pages.workers.moreFilters')}{' '}
                      <i style={{ color: '#626ed4' }} className='fas fa-angle-double-down'></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Row className={'d-none d-md-flex'}>
              <Col xs={12} className={'justify-content-end d-flex flex-wrap'}>
                <div className={'workers_filters__element favoriteFirst'}>
                  <Input
                    id={'favoriteFirst'}
                    type={'checkbox'}
                    checked={favoriteFirst}
                    onChange={() => {
                      setFavoriteFirst(!favoriteFirst)
                      Cookies.set(window.location.pathname + 'favoriteFirst', !favoriteFirst, { expires: 30 })
                    }}
                    className={'mining_preset__checkbox'}
                  />
                  <label htmlFor={'favoriteFirst'}>&nbsp;{t('pages.workers.showObservableFirst')}</label>
                </div>
                {(status && status.value !== 'all') ||
                (dc && dc.value !== 'all') ||
                (coin && coin.length > 0) ||
                (wallet && wallet.length > 0) ||
                (preset && preset.length > 0) ||
                (pool && pool.length > 0) ||
                (miner && miner.length > 0) ||
                minHashrate !== '' ||
                minFilterTemp !== '' ||
                maxHashrate !== '' ||
                maxFilterTemp !== '' ||
                lowHashrate ||
                rejectedShares ||
                rigAlerts ||
                hotGPU ||
                problemGPU ||
                gpuType !== 'all' ||
                stopError ? (
                  <div className={'workers_filters__element favoriteFirst'}>
                    <Button
                      color={'primary'}
                      onClick={() => {
                        setShowFilters(false)
                        setShowFiltersTimer(false)
                        resetFilters()
                      }}
                    >
                      {t('pages.workers.clearFilters')}
                    </Button>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className={'d-md-none workers_filters__elements'}>
              <Col xs={5} sm={4} className={'workers_filters__element'}>
                <label>{t('pages.workers.status')}</label>
                <Select
                  classNamePrefix={'form_control_react_select'}
                  value={status}
                  onChange={option => {
                    setCurrentPage(1)
                    Cookies.set(window.location.pathname + 'dataTablecurrentPage', 1, { expires: 30 })
                    setStatus(option)
                    Cookies.set(window.location.pathname + 'rigStatus', JSON.stringify(option), { expires: 30 })
                  }}
                  options={statusOptions}
                  placeholder={t('pages.workers.all')}
                />
              </Col>
              <Col xs={7} sm={8} className={'mobile-filters'}>
                <i
                  className={!showCheckbox ? 'far fa-check-double' : 'far fa-check-double active'}
                  onClick={() => {
                    setUncheckAll(!uncheckAll)
                    setShowCheckbox(!showCheckbox)
                  }}
                ></i>
                <i
                  className={!favoriteFirst ? 'fas fa-eye' : 'fad fa-eye active'}
                  onClick={() => {
                    setFavoriteFirst(!favoriteFirst)
                    Cookies.set(window.location.pathname + 'favoriteFirst', !favoriteFirst, { expires: 30 })
                  }}
                ></i>
                <Dropdown
                  isOpen={openSortList}
                  toggle={() => {
                    setOpenSortList(!openSortList)
                  }}
                  direction={'left'}
                  className=''
                  // caret="true"
                >
                  <DropdownToggle tag={'div'}>
                    <i className={orderDirection === 'ASC' ? 'fas fa-sort-alpha-up' : 'fas fa-sort-alpha-down-alt'}></i>
                  </DropdownToggle>

                  <DropdownMenu flip={true}>
                    {workers.columns
                      .filter(column => column.sort)
                      .map((column, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => mobileSort(column.field)}
                            className={column.field === orderBy ? 'active' : ''}
                          >
                            <li tag='li'>
                              {column.label}
                              {column.field === orderBy ? (
                                <i
                                  className={
                                    orderDirection === 'ASC' ? 'fas fa-long-arrow-alt-up' : 'fas fa-long-arrow-alt-down'
                                  }
                                ></i>
                              ) : (
                                ''
                              )}
                            </li>
                          </DropdownItem>
                        )
                      })}
                  </DropdownMenu>
                </Dropdown>
                <div
                  className={
                    (status && status.value !== 'all') ||
                    (dc && dc.value !== 'all') ||
                    (coin && coin.length > 0) ||
                    (wallet && wallet.length > 0) ||
                    (preset && preset.length > 0) ||
                    (pool && pool.length > 0) ||
                    (miner && miner.length > 0) ||
                    minHashrate !== '' ||
                    minFilterTemp !== '' ||
                    maxHashrate !== '' ||
                    maxFilterTemp !== '' ||
                    lowHashrate ||
                    rejectedShares ||
                    rigAlerts ||
                    hotGPU ||
                    problemGPU ||
                    gpuType !== 'all' ||
                    stopError
                      ? 'active'
                      : ''
                  }
                >
                  <MobileFilters resetFilters={resetFilters} applyFilters={applyFilters} />
                </div>
                {(status && status.value !== 'all') ||
                (dc && dc.value !== 'all') ||
                (coin && coin.length > 0) ||
                (wallet && wallet.length > 0) ||
                (preset && preset.length > 0) ||
                (pool && pool.length > 0) ||
                (miner && miner.length > 0) ||
                minHashrate !== '' ||
                minFilterTemp !== '' ||
                maxHashrate !== '' ||
                maxFilterTemp !== '' ||
                lowHashrate ||
                rejectedShares ||
                rigAlerts ||
                hotGPU ||
                problemGPU ||
                gpuType !== 'all' ||
                stopError ? (
                  <i
                    onClick={() => {
                      setShowFilters(false)
                      setShowFiltersTimer(false)
                      resetFilters()
                    }}
                    className='fad fa-times-square clearFilters'
                  ></i>
                ) : null}
              </Col>
              {(status && status.value !== 'all') ||
              (dc && dc.value !== 'all') ||
              (coin && coin.length > 0) ||
              (wallet && wallet.length > 0) ||
              (preset && preset.length > 0) ||
              (pool && pool.length > 0) ||
              (miner && miner.length > 0) ||
              minHashrate !== '' ||
              minFilterTemp !== '' ||
              maxHashrate !== '' ||
              maxFilterTemp !== '' ||
              lowHashrate ||
              rejectedShares ||
              rigAlerts ||
              hotGPU ||
              problemGPU ||
              gpuType !== 'all' ||
              stopError ? (
                <Col sm={4} md={3} className={'workers_filters__element favoriteFirst d-none d-md-block'}>
                  <Button
                    color={'primary'}
                    onClick={() => {
                      setShowFilters(false)
                      setShowFiltersTimer(false)
                      resetFilters()
                    }}
                  >
                    {t('pages.workers.clearFilters')}
                  </Button>
                </Col>
              ) : null}
            </Row>
            <TableWorkers
              className={'workers__table'}
              data={workers}
              firstColumnCheckbox
              stickyHeader
              checkRow={checkFunc}
              checkAll={checkAllFunc}
              duplicateEntries
              duplicatePagination
              setVisible={setVisibleRigs}
              addToFavorite={watchForWorker}
              favoriteFirst={favoriteFirst}
              searchable
              uncheckAll={uncheckAll}
              showCheckbox={showCheckbox}
              total={total}
              setShowCount={setShowCount}
              showCount={showCount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              search={search}
              setSearch={setSearch}
              setSort={setSort}
            />
          </div>
          {isVisible[0] && (
            <button
              onClick={scrollToTop}
              id='scrollToTopButton'
              title='Go to top'
              style={{ display: isVisible[1] }}
              className='fas fa-arrow-alt-circle-up'
            >
              {t('pages.workers.topErrow')}
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  rigsList: store.rigs.rigsList,
  total: store.rigs.total,
  delayDataUpdate: store.rigs.delayDataUpdate,
  coinsList: store.presets.coinsList,
  poolsList: store.presets.poolsList,
  walletsList: store.presets.walletsList,
  presetsList: store.presets.miningPresetList,
  minersList: store.presets.minersList,
  dcManagement: store.dcManagement,
  rigsStat: store.rigs.rigsStat,
  isRigsListDownload: store.rigs.isRigsListDownload,
})

const mapDispatchToProps = {
  setRigsList: actions.setRigsList,
  setRoomsList: dcManagementActions.setRoomsList,
  deleteRig: actions.deleteRig,
  setRigMinerAction: actions.setRigMinerAction,
  setMiningPresetList: presetActions.setMiningPresetList,
  setWalletsList: presetActions.setWalletsList,
  visibleWorkerActions: mainActions.visibleWorkerActions,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
  setPoolsList: presetActions.setPoolsList,
  setMinersList: presetActions.setMinersList,
  setSlotsList: actionsProfiles.setSlotsList,
  setAlgoCoinsList: actionsProfiles.setAlgoCoinsList,
  setRigsData: actions.setRigsData,
  setFilterRigsList: actions.setFilterRigsList,
  setDopFilterRigsList: actions.setDopFilterRigsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkersPage)
