import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export const CheckListErrors = ({ error, goToActivityLogs, ignore }) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={
        error.error.toLowerCase().includes('not applied')
          ? 'checklist-row checklist-row_yellow'
          : 'checklist-row checklist-row_red'
      }
    >
      <span className='checklist-status'>
        <i
          className={`checklist-status__icon
                        ${
                          error.error.toLowerCase().includes('fatal')
                            ? 'fas fa-bug'
                            : error.error.toLowerCase().includes('fallen off') ||
                              error.error.toLowerCase().includes('not responding')
                            ? 'fas fa-puzzle-piece'
                            : error.error.toLowerCase().includes('unspecified launch') ||
                              error.error.toLowerCase().includes('check gpu') ||
                              error.error.toLowerCase().includes('cuda')
                            ? 'fas fa-magic'
                            : 'far fa-exclamation-triangle'
                        }`}
        ></i>
        <p className='checklist-row__descr'>
          {t('pages.workers.workerError')}: {error.error}
          {error.errorsnum > 1 && !error.error.toLowerCase().includes('fallen off the bus')
            ? ' ( ' +
              error.errorsnum +
              ' times, ' +
              'last date - ' +
              moment(error.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
              ' ) '
            : (error.errorsnum === 1 && !error.error.includes('fallen off the bus')) ||
              (!error.count && error.error.includes('fallen off the bus'))
            ? ' (' + moment(error.updatedAt).format('YYYY-MM-DD HH:mm:ss') + ')'
            : ''}
        </p>
      </span>
      <div className='checklist-row__nav'>
        {error.error.includes('fallen off the bus') && (
          <button className='checklist-row__nav-btn' tooltip={t('pages.workers.seeMore')} onClick={goToActivityLogs}>
            i
          </button>
        )}

        <button className='checklist-row__nav-btn' onClick={() => ignore(error)}>
          {t('pages.workers.ignore')}
        </button>
      </div>
    </div>
  )
}
